import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "feature-flags"
    }}>{`Feature-flags`}</h2>
    <p>{`Carbon uses `}<inlineCode parentName="p">{`feature-flags`}</inlineCode>{` to toggle new features on and off. We currently have two `}<inlineCode parentName="p">{`feature-flags`}</inlineCode>{` available for testing:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Experimental Components`}</strong>{` use `}<inlineCode parentName="li">{`components-x`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`UI Shell`}</strong>{` uses `}<inlineCode parentName="li">{`ui-shell`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`To turn on either of the feature flags, include the `}<strong parentName="p">{`feature-flag`}</strong>{` variable into your SCSS file before importing `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{`, then set `}<inlineCode parentName="p">{`components-x`}</inlineCode>{` and/or `}<inlineCode parentName="p">{`ui-shell`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`$feature-flags: (
  components-x: true,
  ui-shell: true,
);
@import 'carbon-components/src/globals/scss/styles';
`}</code></pre>
    <p><em parentName="p">{`Note: You must be using Sass to take advantage of code using feature flags. The Sass feature flags determine which CSS is compiled.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      